import React from "react";
import "./newsletter.css"

export default function NewsLetter() {
  return (
    <div className="newsletter">
      <div className="newsletter-container">
        <div className="newsletter-heading">
          <h4 className="news-header">Signup For NewsLetter</h4>
          <p className="news-para">
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer
          </p>
        </div>
        <div className="newsletter-form">
          <form action="" method="post" className="email-form">
            <input
              className="email-field"
              placeholder="Email Address"
              type="email"
              name="email"
              id="email"
            />
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
