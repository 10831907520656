import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import NewsLetter from "./components/NewsLetter/NewsLetter";
import SupportCards from "./components/SupportCards/SupportCards";
import Why from "./components/Careers/SupportCards/SupportCards";
import Join from "./components/Careers/Join/Join"
import Carousel from "./components/Home/Carousel/Carousel";
import "./about.css";
import Healthy from "./components/Careers/About/About";
import PositionCareer from "./components/Careers/PositionBar/PositionBar";

export default function Career() {
  return (
    <div className="about">
      <Navbar />
      <Carousel />
      <PositionCareer/>   
      <Healthy/>  
      <Join/>
      <Why/>
      <SupportCards />
      <NewsLetter />
      <Footer />
    </div>
  );
}
