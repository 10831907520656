import React from "react";
import "./footer.css";
import map from "./assets/map.png";
import email from "./assets/emai.png";
import insta from "./assets/insta.png";
import fb from "./assets/fb.png";
import ph from "./assets/ph.png";
import tik from "./assets/tik.png";
import yt from "./assets/yt.png";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-sect">
          <div className="quick-links l-h footer-push">
            <h4>Quick Links</h4>
            <div className="quicking">
              <a href="/">Home</a>
              <a href="/services">Our Services</a>
              <a href="/about">About Us</a>
              <a href="/career">Careers</a>
              <a href="/contact">Contact Us</a>
            </div>
          </div>

          <div className="address-links l-h  footer-push">
            <h4>Address</h4>
            <div className="office-address">
              <div className="map-icon-box">
                <img className="map-icon" src={map} alt="" />
              </div>
              <div className="address">
                <p>Office 1507 </p>
                <p> Grosvenor Business Tower </p>
                <p>Dubai - United Arab Emirates</p>
              </div>
            </div>
          </div>

          <div className="contact-links l-h  footer-push">
            <h4>Contact</h4>
            <div>
              <div className="contact-box">
                <div className="single-img-box">
                  <div className="contact-img-box">
                    <img src={ph} alt="" />
                  </div>
                  <div className="">
                    <p className="number">+971 800 50 60</p>
                  </div>
                </div>
                <div className="single-img-box">
                  <div className="contact-img-box">
                    <img src={email} alt="" />
                  </div>
                  <div className="">
                    <p className="number">info@citydoctor.ae</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="quick-links l-h footer-push">
            <h4>Quick Links</h4>
            <div children>
              <div className="social-links">
                <div>
                  <img src={yt} alt="" />
                </div>
                <div>
                  <img src={insta} alt="" />
                </div>
                <div>
                  <img src={tik} alt="" />
                </div>
                <div>
                  <img src={fb} alt="" />
                </div>
              </div>
              <div className="city">citydoctor</div>
            </div>
          </div>
        </div>
      </div>
      <div className="terms-container">
        © 2023 CITY DOCTOR HOME HEALTHCARE. ALL RIGHTS RESERVED.
      </div>
    </div>
  );
}
