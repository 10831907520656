import React from "react";
import "./mission.css";

export default function Mission() {
  return (
    <div className="mission-vission">
      <div className="mission-container">
        <div className="mission-box bxx">
          <h3>Our Mission</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam ex
            voluptatibus fugit, pariatur, eius reiciendis minus, libero sequi
            deleniti ullam neque! Inventore perspiciatis eveniet esse ipsum
            consequuntur dolorum quia vel.
          </p>
        </div>
        <div className="vision-box bxx">
          <h3>Our Vision</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam ex
            voluptatibus fugit, pariatur, eius reiciendis minus, libero sequi
            deleniti ullam neque! Inventore perspiciatis eveniet esse ipsum
            consequuntur dolorum quia vel.
          </p>
        </div>
      </div>
    </div>
  );
}
