import React from "react";
import "./support.css";
import map from "./assets/location.png";
import hand from "./assets/business-support.png";
import patient from "./assets/patient-support.png";
import arrow from "./assets/Layer 2.png";

import g20 from "./assets/g2107.png";

export default function SupportCards() {
  return (
    <div className="support-cards-career">
      <h3>Why Join With Us?</h3>
      <div className="support-cards-container-career">
        <div className="support-card-career">
          <div className="support-img-anchor">
            <img src={g20} alt="" />
          </div>
          <div className="support-linkd">
            <h3>Heading</h3>
            <p className="support-anchor-text">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
              ducimus ipsam aperiam, veritatis expedita quam minima officia
              excepturi. Ea, perspiciatis.
            </p>
          </div>
        </div>

        <div className="support-card-career">
          <div className="support-img-anchor">
            <img src={g20} alt="" />
          </div>
          <div className="support-linkd">
          <h3>Heading</h3>
            <p className="support-anchor-text">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
              ducimus ipsam aperiam, veritatis expedita quam minima officia
              excepturi. Ea, perspiciatis.
            </p>
          </div>
        </div>

        <div className="support-card-career">
          <div className="support-img-anchor">
            <img src={g20} alt="" />
          </div>
          <div className="support-linkd">
          <h3>Heading</h3>
            <p className="support-anchor-text">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
              ducimus ipsam aperiam, veritatis expedita quam minima officia
              excepturi. Ea, perspiciatis.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
