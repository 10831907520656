import React from "react";
import hands from "./assets/business-hands-joined-together-teamwork.png";
import arrow from "./assets/Layer 2.png";
import "./team.css";

export default function Team() {
  return (
    <div className="teaming">
      <div className="team-container">
        <div className="team-left-cont">
          <div className="team-img-box">
            <img src={hands} alt="" />
          </div>
        </div>
        <div className="team-right-cont">
          <h4>Meet The Team</h4>
          <h3>Our Team</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id
            blanditiis non amet maiores suscipit iste, quam fugiat soluta
            laborum nostrum quod consectetur expedita modi recusandae esse error
            debitis reprehenderit, sequi explicabo odio cumque possimus animi
            accusamus? Hic eveniet quod sunt?
          </p>
          <div className="learn-more-boxs">
            <h6>Learn More</h6>
            <div className="learmore-box-img">
              <img src={arrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
