import React from "react";
import "./support.css";
import map from "./assets/location.png";
import hand from "./assets/business-support.png";
import patient from "./assets/patient-support.png";
import arrow from "./assets/Layer 2.png"

export default function SupportCards() {
  return (
    <div className="support-cards">
      <div className="support-cards-container">
    
        <div className="support-card">
          <div className="support-img">
            <img src={map} alt="" />
          </div>
          <div className="support-text">
            <h5>Visit Us</h5>
            <p>Find locations to HQ</p>
          </div>
          <div className="support-linkd">
            <a href="/contact#location" className="support-link">
              <p className="support-anchor-text">Get Direction</p>
              <img className="support-anchor-arrow" src={arrow} alt="" />
            </a>
          </div>
        </div>

        <div className="support-card grey">
          <div className="support-img">
            <img src={patient} alt="" />
          </div>
          <div className="support-text">
            <h5>Support</h5>
            <p>Knowledge base for patients</p>
          </div>
          <div className="support-linkd">
            <a href="/services#providers" className="support-link">
              <p className="support-anchor-text">Get Support</p>
              <img className="support-anchor-arrow" src={arrow} alt="" />
            </a>
          </div>
        </div>

        <div className="support-card">
          <div className="support-img">
            <img src={hand} alt="" />
          </div>
          <div className="support-text">
            <h5>Business Support</h5>
            <p>Information for suppliers and more</p>
          </div>
          <div className="support-linkd">
            <a href="/" className="support-link">
              <p className="support-anchor-text">Get Direction</p>
              <img className="support-anchor-arrow" src={arrow} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
