import React from "react";
import "./Thinking.css";

import left from "./assets/lefff.png";
import right from "./assets/riii.png";
import layer from "./assets/Layer 2.png";
import person1 from "./assets/360_F_175590540_SKQm3qgEvSr1OCsHrp3M7yXifasIpvm8.png";
import person2 from "./assets/happy-free-woman-having-fun-dancing-with-hands-up-looking-empty-space-standing-white-casual-clothes.png";
import person3 from "./assets/portrait-beautiful-female-model-using-smartphone-app-standing-profile-turn-face-with-smile-front-sending-message-social-media-chat-white-wall.png";
import stars from "./assets/stars.png";
import Carouseling from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ThinkingSettings = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 1000, min: 0 },
    items: 1
  }
};


export default function Thinking() {
  return (
    <div className="thinking">
      <div className="think-heading">
        <h3>What People Thinking & Experience</h3>
        <h3>About Us</h3>
      </div>
      <Carouseling responsive={ThinkingSettings}>
        <div className="card">
          <div className="card-img">
            <img src={person2} alt="" />
          </div>
          <div className="card-header">
            <h4>Heading</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              distinctio nisi iure dolorem molestiae a non nam, modi ipsam
              veritatis libero magni ratione, eaque fugiat rerum illum atque
              ut veniam?
            </p>
          </div>
          <div className="starts">
            <img src={stars} alt="" />
          </div>
        </div>
        <div className="card">
          <div className="card-img">
            <img src={person3} alt="" />
          </div>
          <div className="card-header">
            <h4>Heading</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              distinctio nisi iure dolorem molestiae a non nam, modi ipsam
              veritatis libero magni ratione, eaque fugiat rerum illum atque
              ut veniam?
            </p>
          </div>
          <div className="starts">
            <img src={stars} alt="" />
          </div>
        </div>
        <div className="card">
          <div className="card-img">
            <img src={person1} alt="" />
          </div>
          <div className="card-header">
            <h4>Heading</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              distinctio nisi iure dolorem molestiae a non nam, modi ipsam
              veritatis libero magni ratione, eaque fugiat rerum illum atque
              ut veniam?
            </p>
          </div>
          <div className="starts">
            <img src={stars} alt="" />
          </div>
        </div>

        <div className="card">
          <div className="card-img">
            <img src={person2} alt="" />
          </div>
          <div className="card-header">
            <h4>Heading</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              distinctio nisi iure dolorem molestiae a non nam, modi ipsam
              veritatis libero magni ratione, eaque fugiat rerum illum atque
              ut veniam?
            </p>
          </div>
          <div className="starts">
            <img src={stars} alt="" />
          </div>
        </div>
        <div className="card">
          <div className="card-img">
            <img src={person3} alt="" />
          </div>
          <div className="card-header">
            <h4>Heading</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              distinctio nisi iure dolorem molestiae a non nam, modi ipsam
              veritatis libero magni ratione, eaque fugiat rerum illum atque
              ut veniam?
            </p>
          </div>
          <div className="starts">
            <img src={stars} alt="" />
          </div>
        </div>
        <div className="card">
          <div className="card-img">
            <img src={person1} alt="" />
          </div>
          <div className="card-header">
            <h4>Heading</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              distinctio nisi iure dolorem molestiae a non nam, modi ipsam
              veritatis libero magni ratione, eaque fugiat rerum illum atque
              ut veniam?
            </p>
          </div>
          <div className="starts">
            <img src={stars} alt="" />
          </div>
        </div>
      </Carouseling>
    </div >
  );
}