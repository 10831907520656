import React from "react";
import "./navbar.css";
import logo from "./assets/logos.png";
import down from "./assets/down.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbars from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";


export default function Navbar() {
  return (
    <div className="navbar">
      <div className="nav">
        <div className="list-box">
          <Navbars expand="lg" className="bg-body-tertiary" sticky="right">
            <Container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Navbars.Brand href="#home" className="bts-img">
                <div className="img-box">
                  <img className="nav-img" src={logo} alt="" />
                </div>
              </Navbars.Brand>
              <Navbars.Toggle aria-controls="basic-navbar-nav" />
              <Navbars.Collapse id="basic-navbar-nav" className="bts-list">
                <Nav className="me-auto">
                  <Nav.Link className="nav-itm" href="/">
                    Home
                  </Nav.Link>
                  <Nav.Link className="nav-itm" href="/about">
                    About
                  </Nav.Link>
                  <Nav.Link className="nav-itm" href="/services">
                    Services
                  </Nav.Link>
                  <Nav.Link className="nav-itm" href="/contact">
                    Contact
                  </Nav.Link>
                  <Nav.Link className="nav-itm" href="/career">
                    Career
                  </Nav.Link>
                </Nav>
              </Navbars.Collapse>
            </Container>
          </Navbars>
        </div>
      </div>
    </div>
  );
}
