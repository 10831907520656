import React from "react";
import "./mubadala.css";

export default function Mubadala() {
  return (
    <div className="mubadala">
      <div className="mubadala-container">
        <h3>Mubadala Health In Numbers</h3>
        <div className="numbers">
          
          <div className="number-container">
            <h2 className="number">12</h2>
            <h4>Healthcare Providers</h4>
          </div>

          <div className="number-container">
            <h2 className="number">44</h2>
            <h4>Facilities</h4>
          </div>

          <div className="number-container">
            <h2 className="number">9K+</h2>
            <h4>Caregivers</h4>
          </div>

          <div className="number-container">
            <h2 className="number">100+</h2>
            <h4>Service Lines</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
