import React from "react";
import "./enquiries.css";

export default function Enquiries() {
  return (
    <div className="enquries">
      <h3>For All Enquiries</h3>
      <form action="/" method="post">

        <div className="formation-1">

          <div className="half-field">
            <label htmlFor="">First Name*</label>
            <input type="text" />
          </div>

          <div className="half-field">
            <label htmlFor="">Last Name *</label>
            <input type="text" />
          </div>

          <div className="half-field">
            <label htmlFor="">Phone *</label>
            <input type="number" />
          </div>

          <div className="half-field">
            <label htmlFor="">Email *</label>
            <input type="email" />
          </div>

        </div>

        <div className="formation-2">
          <div className="full-field subject">
            <label htmlFor="">Subject *</label>
            <select className="selective" name="service" id="service">
              <option value="volvo">Service</option>
            </select>
          </div>

          <div className="full-field comments">
            <label htmlFor="">Comments *</label>
            <input type="text" />
          </div>

          <div className="subscribe">
            <input type="radio" />
            <label className="radio-label" htmlFor="">Do you want to subscribe to our News Letter?</label>
            <input className="sub" type="button" value="Submit" />
          </div>
          <input className="sub-2" type="button" value="Submit" />
        </div>
      </form>
    </div>
  );
}
