import React from "react";
import "./providers.css";
import plus from "./assets/Path 26.png";

export default function Providers() {
  return (
    <div className="providers" id="providers">
      <div className="provider-container">
        <div className="provider-card">
          <div className="provider-card-box">
            <div className="provider-img-box">
              <img src={plus} alt="" />
            </div>
          </div>
          <div className="provider-description-box">
            <h3>Service-1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
              totam excepturi molestiae adipisci aspernatur ut nobis animi odit
              tempora ratione?
            </p>
          </div>
        </div>

        <div className="provider-card">
          <div className="provider-card-box">
            <div className="provider-img-box">
              <img src={plus} alt="" />
            </div>
          </div>
          <div className="provider-description-box">
            <h3>Service-1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
              totam excepturi molestiae adipisci aspernatur ut nobis animi odit
              tempora ratione?
            </p>
          </div>
        </div>

        <div className="provider-card">
          <div className="provider-card-box">
            <div className="provider-img-box">
              <img src={plus} alt="" />
            </div>
          </div>
          <div className="provider-description-box">
            <h3>Service-1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
              totam excepturi molestiae adipisci aspernatur ut nobis animi odit
              tempora ratione?
            </p>
          </div>
        </div>

        <div className="provider-card">
          <div className="provider-card-box">
            <div className="provider-img-box">
              <img src={plus} alt="" />
            </div>
          </div>
          <div className="provider-description-box">
            <h3>Service-1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
              totam excepturi molestiae adipisci aspernatur ut nobis animi odit
              tempora ratione?
            </p>
          </div>
        </div>

        <div className="provider-card">
          <div className="provider-card-box">
            <div className="provider-img-box">
              <img src={plus} alt="" />
            </div>
          </div>
          <div className="provider-description-box">
            <h3>Service-1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
              totam excepturi molestiae adipisci aspernatur ut nobis animi odit
              tempora ratione?
            </p>
          </div>
        </div>

        <div className="provider-card">
          <div className="provider-card-box">
            <div className="provider-img-box">
              <img src={plus} alt="" />
            </div>
          </div>
          <div className="provider-description-box">
            <h3>Service-1</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
              totam excepturi molestiae adipisci aspernatur ut nobis animi odit
              tempora ratione?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
