import React from 'react'
import docs from "./assets/Group 149.png"
import "./docs.css";

export default function Docs() {
  return (
    <div className='docs'>
        <div className="docs-container">
            <img src={docs} alt="" />
        </div>
    </div>
  )
}
