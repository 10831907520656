import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Carousel from "./components/Home/Carousel/Carousel";
import Portfolio from "./components/Home/Portfolio/Portfolio";
import About from "./components/Home/About/About";
import Mubadala from "./components/Home/Mubadala/Mubadala";
import Thinking from "./components/Home/Thinking/Thinking";
import SupportCards from "./components/SupportCards/SupportCards"
import NewsLetter from "./components/NewsLetter/NewsLetter";

export default function Home() {
  return (
    <div className="home" style={{ backgroundColor: "white" }}>
      <Navbar />
      <Carousel />
      <Portfolio />
      <About />
      <Mubadala />
      <Thinking />
      <SupportCards />
      <NewsLetter />
      <Footer />
    </div>
  );
}
