import React from "react";
import "./positionbar.css";

export default function Position() {
  return (
    <div className="position">
      <div className="position-container">
        <p>Home {">"} Services</p>
      </div>
    </div>
  );
}