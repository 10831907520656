import React from "react";
import "./join.css";
import life from "./assets/Group 12.png";

export default function Join() {
  return (
    <div className="join">
      <h3>We offer Different Services To</h3>
      <h3>Improve your Health</h3>
      <div className="join-container">
        <div className="join-img-box">
          <img src={life} alt="" className="join-life" />
        </div>
        <div className="join-text-box">
          <p className="join-text">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde,
            nesciunt. Ea non eum ex molestiae?
          </p>
        </div>
      </div>
    </div>
  );
}
