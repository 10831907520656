import "./about.css";
import glasses from "./assets/glasses.png";
import seat from "./assets/seat.png";
import layer from "./assets/Layer2.png";

export default function About() {
  return (
    <div className="about-home">
      <div className="about-home-container">
        <div className="about-1">
          <div className="about-left od-1">
            <h3>Chosen For Excellence</h3>
            <h3>Trusted For Care</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. In illo
              sint minus omnis laudantium consectetur deleniti. Dignissimos modi
              ut, ex rerum accusantium repellat beatae neque, architecto ipsa
              mollitia voluptatum voluptatibus nostrum magnam quisquam eius vero
              harum suscipit, debitis quia distinctio!
            </p>

            <a href="/" className="learn-more">
              <p className="learn-more-text">Learn More</p>
              <img className="learn-more-arrow" src={layer} alt="" />
            </a>
          </div>
          <div className="about-right od-2">
            <img src={seat} alt="" />
          </div>
        </div>
        <div className="about-2 ">
          <div className="about-left">
            <img src={glasses} alt="" />
          </div>
          <div className="about-right">
            <h3>World-class care</h3>
            <h3>regional understanding</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. In illo
              sint minus omnis laudantium consectetur deleniti. Dignissimos modi
              ut, ex rerum accusantium repellat beatae neque, architecto ipsa
              mollitia voluptatum voluptatibus nostrum magnam quisquam eius vero
              harum suscipit, debitis quia distinctio!
            </p>

            <a href="/" className="learn-more">
              <p className="learn-more-text">Learn More</p>
              <img className="learn-more-arrow" src={layer} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="explaination-box">
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Mollitia
          animi, voluptates repellat eum ipsam porro quia voluptate odit
          necessitatibus delectus facilis debitis deleniti impedit similique
          recusandae veniam perspiciatis nostrum eius veniam ea. Officia?
        </p>
      </div>
    </div>
  );
}
