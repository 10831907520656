import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import NewsLetter from "./components/NewsLetter/NewsLetter";
import PositionBar from "./components/About/PositionBar/PositionBar";
import SupportCards from "./components/SupportCards/SupportCards";
import Carousel from "./components/Home/Carousel/Carousel";
import Portfolio from "./components/Home/Portfolio/Portfolio";
import Support from "./components/About/Support/Support"
import "./about.css";
import Enquiries from "./components/About/Enquiries/Enquiries";
import Map from "./components/About/Map/Map";

export default function About() {
  return (
    <div className="about">
      <Navbar />
      <Carousel />
      <PositionBar />
      <Support />
      <Enquiries/>
      <Map/>
      <SupportCards />
      <NewsLetter />
      <Footer />
    </div>
  );
}
