import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import NewsLetter from "./components/NewsLetter/NewsLetter";
import Abou from "./components/Contact/About/About";
import Carousel from "./components/Home/Carousel/Carousel";
import PositionBar from "./components/Contact/PositionBar/PositionBar";
import Docs from "./components/Contact/Docs/Docs";
import Mission from "./components/Contact/Mission/Mission";
import Values from "./components/Contact/Values/Values";
import Team from "./components/Contact/Team/Team"

export default function About() {
  return (
    <div className="about">
      <Navbar />
      <Carousel />
      <PositionBar />
      <Abou />
      <Docs />
      <Mission />
      <Values />
      <Team/>
      <NewsLetter />
      <Footer />
    </div>
  );
}
