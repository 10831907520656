import React from "react";
import map from "./assets/mapping.png";
import "./map.css";

export default function Map() {
  return (
    <div className="Map" id="location">
      <div className="map-container">
        <img src={map} alt="" />
      </div>
    </div>
  );
}
