import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import NewsLetter from "./components/NewsLetter/NewsLetter";
import About from "./components/Services/About/About";
import Carousel from "./components/Home/Carousel/Carousel";
import PositionBar from "./components/Services/PositionBar/PositionBar";
import Join from "./components/Services/Join/Join";
import "./servicers.css";
import Providers from "./components/Services/Providers/Providers";

export default function Services() {
  return (
    <div className="servicers">
      <Navbar />
      <Carousel />
      <PositionBar />
      <Join />
      <Providers />
      <About />
      <NewsLetter/>
      <Footer />
    </div>
  );
}
