import React from "react";
import "./values.css";
import iron from "./assets/g2107.png";

export default function Values() {
  return (
    <div className="values">
      <h3>Our Values</h3>
      <div className="values-container">
        <div className="value-box">
          <div className="value-img-box">
            <img src={iron} alt="" />
          </div>
          <div className="value-hd-box">
            <h3>Heading</h3>
          </div>
        </div>

        <div className="value-box">
          <div className="value-img-box">
            <img src={iron} alt="" />
          </div>
          <div className="value-hd-box">
            <h3>Heading</h3>
          </div>
        </div>

        <div className="value-box">
          <div className="value-img-box">
            <img src={iron} alt="" />
          </div>
          <div className="value-hd-box">
            <h3>Heading</h3>
          </div>
        </div>

        <div className="value-box">
          <div className="value-img-box">
            <img src={iron} alt="" />
          </div>
          <div className="value-hd-box">
            <h3>Heading</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
