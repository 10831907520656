import React from "react";
import "./carousel.css";
import left from "./assets/left.png";
import right from "./assets/right.png";
import Carouseling from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const carouselSettings = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Carousel() {
  return (
    <Carouseling responsive={carouselSettings} showDots={window.innerWidth < 850 ? true : false}>
      <div className="carousel-home-1">
        <div className="carousel-line">
          <div className="carousel-text">
            <h5>Axis Care Health</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              et necessitatibus temporibus mollitia! Dolorem eum quod eos. Nihil
              nisi quae rem corrupti ullam eaque? Iusto?
            </p>
          </div>
          {/* <div className="carousel-arrows">
            <div className="arrow">
              <img src={left} alt=""></img>
            </div>
            <div className="arrow">
              <img src={right} alt=""></img>
            </div>
          </div> */}
        </div>
      </div>

      <div className="carousel-home-2">
        <div className="carousel-line">
          <div className="carousel-text">
            <h5>About Us</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              et necessitatibus temporibus mollitia! Dolorem eum quod eos. Nihil
              nisi quae rem corrupti ullam eaque? Iusto?
            </p>
          </div>
          {/* <div className="carousel-arrows">
            <div className="arrow">
              <img src={left} alt=""></img>
            </div>
            <div className="arrow">
              <img src={right} alt=""></img>
            </div>
          </div> */}
        </div>
      </div>

      <div className="carousel-home-3">
        <div className="carousel-line">
          <div className="carousel-text">
            <h5>Careers Across Our Networks</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              et necessitatibus temporibus mollitia! Dolorem eum quod eos. Nihil
              nisi quae rem corrupti ullam eaque? Iusto?
            </p>
          </div>
          {/* <div className="carousel-arrows">
            <div className="arrow">
              <img src={left} alt=""></img>
            </div>
            <div className="arrow">
              <img src={right} alt=""></img>
            </div>
          </div> */}
        </div>
      </div>

      <div className="carousel-home-4">
        <div className="carousel-line">
          <div className="carousel-text">
            <h5>Our Services</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              et necessitatibus temporibus mollitia! Dolorem eum quod eos. Nihil
              nisi quae rem corrupti ullam eaque? Iusto?
            </p>
          </div>
          {/* <div className="carousel-arrows">
            <div className="arrow">
              <img src={left} alt=""></img>
            </div>
            <div className="arrow">
              <img src={right} alt=""></img>
            </div>
          </div> */}
        </div>
      </div>

      <div className="carousel-home-5">
        <div className="carousel-line">
          <div className="carousel-text">
            <h5>Contact Us</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              et necessitatibus temporibus mollitia! Dolorem eum quod eos. Nihil
              nisi quae rem corrupti ullam eaque? Iusto?
            </p>
          </div>
          {/* <div className="carousel-arrows">
            <div className="arrow">
              <img src={left} alt=""></img>
            </div>
            <div className="arrow">
              <img src={right} alt=""></img>
            </div>
          </div> */}
        </div>
      </div>
    </Carouseling>
  );
}
