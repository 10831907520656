import React from "react";
import "./portfolio.css";
import left from "./assets/left.png";
import right from "./assets/right.png";
import link1 from "./assets/link1.png";
import Carouseling from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import { PortfolioSettings } from "../../CarouselSettings";


export const PortfolioSettings = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Portfolio() {
  return (
    <div className="portfolio" >
      <h4>Healthcare Portfolio</h4>
      <Carouseling responsive={PortfolioSettings} >

        <div className="pf-card">
          <h6>Header-1</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, iure
            explicabo? Vero quod fuga dolore!
          </p>
          <a href="/" className="learn-more">
            <p className="learn-more-text">Learn More</p>
            <img className="learn-more-imging" src={link1} alt="" />
          </a>
        </div>

        <div className="pf-card">
          <h6>Header-1</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, iure
            explicabo? Vero quod fuga dolore!
          </p>
          <a href="/" className="learn-more">
            <p className="learn-more-text">Learn More</p>
            <img className="learn-more-imging" src={link1} alt="" />
          </a>
        </div>

        <div className="pf-card">
          <h6>Header-1</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, iure
            explicabo? Vero quod fuga dolore!
          </p>
          <a href="/" className="learn-more">
            <p className="learn-more-text">Learn More</p>
            <img className="learn-more-imging" src={link1} alt="" />
          </a>
        </div>

        <div className="pf-card">
          <h6>Header-1</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, iure
            explicabo? Vero quod fuga dolore!
          </p>
          <a href="/" className="learn-more">
            <p className="learn-more-text">Learn More</p>
            <img className="learn-more-imging" src={link1} alt="" />
          </a>
        </div>

        <div className="pf-card">
          <h6>Header-1</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, iure
            explicabo? Vero quod fuga dolore!
          </p>
          <a href="/" className="learn-more">
            <p className="learn-more-text">Learn More</p>
            <img className="learn-more-imging" src={link1} alt="" />
          </a>
        </div>

        <div className="pf-card">
          <h6>Header-1</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, iure
            explicabo? Vero quod fuga dolore!
          </p>
          <a href="/" className="learn-more">
            <p className="learn-more-text">Learn More</p>
            <img className="learn-more-imging" src={link1} alt="" />
          </a>
        </div>
      </Carouseling>
    </div>
  );
}
